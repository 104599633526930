// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-arrivals-index-js": () => import("./../../../src/pages/new-arrivals/index.js" /* webpackChunkName: "component---src-pages-new-arrivals-index-js" */),
  "component---src-pages-new-arrivals-maternity-js": () => import("./../../../src/pages/new-arrivals/maternity.js" /* webpackChunkName: "component---src-pages-new-arrivals-maternity-js" */),
  "component---src-pages-new-arrivals-plus-js": () => import("./../../../src/pages/new-arrivals/plus.js" /* webpackChunkName: "component---src-pages-new-arrivals-plus-js" */),
  "component---src-pages-new-arrivals-women-js": () => import("./../../../src/pages/new-arrivals/women.js" /* webpackChunkName: "component---src-pages-new-arrivals-women-js" */),
  "component---src-templates-catalog-page-catalog-page-js": () => import("./../../../src/templates/catalogPage/catalogPage.js" /* webpackChunkName: "component---src-templates-catalog-page-catalog-page-js" */),
  "component---src-templates-product-page-product-page-js": () => import("./../../../src/templates/productPage/productPage.js" /* webpackChunkName: "component---src-templates-product-page-product-page-js" */)
}

